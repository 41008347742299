@mixin absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

//MEDIA QUERY MANAGER
/*
$breakpoint argument choices
- s-ees
- s-es
- s-s
- s-m
- s-b
- s-eb
- s-eeb
- s-large
*/
@mixin respond($breakpoint) {
  @if $breakpoint == s-ees {
    @media only screen and (max-width: $bp-smallest) {
      @content;
    }
  }
  @if $breakpoint == s-es {
    @media only screen and (max-width: $bp-smaller) {
      @content;
    }
  }
  @if $breakpoint == s-s {
    @media only screen and (max-width: $bp-small) {
      @content;
    }
  }
  @if $breakpoint == s-m {
    @media only screen and (max-width: $bp-medium) {
      @content;
    }
  }
  @if $breakpoint == s-b {
    @media only screen and (max-width: $bp-big) {
      @content;
    }
  }
  @if $breakpoint == s-eb {
    @media only screen and (max-width: $bp-bigger) {
      @content;
    }
  }
  @if $breakpoint == s-eeb {
    @media only screen and (max-width: $bp-biggest) {
      @content;
    }
  }
  @if $breakpoint == s-l {
    @media only screen and (max-width: $bp-large) {
      @content;
    }
  }
}
