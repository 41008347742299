.form {
  &__title {
    padding: 1rem;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: 0.3rem;
    word-spacing: 0.5rem;
    color: var(--color-secondary-dark);
    border-bottom: 0.2rem solid currentColor;
    margin-bottom: 2rem;
  }

  &__group {
    color: var(--color-secondary-dark);
  }

  &__copyright {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.3rem 1rem;
    color: var(--color-white);
    background-color: var(--color-grey-dark-3);
    font-weight: 700;
    width: max-content;
    border-radius: 20px;
  }
}
