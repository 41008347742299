hr {
  margin: 1rem 0 0 0;
  color: inherit;
  border: 0;
  border-top: none;
  opacity: 0.25;
  height: 1px;

  &.horizontal {
    &.light {
      background-image: linear-gradient(
        90deg,
        hsla(0, 0%, 100%, 0),
        #fff,
        hsla(0, 0%, 100%, 0)
      );
    }

    &.dark {
      background-image: linear-gradient(
        90deg,
        hsla(0, 0%, 100%, 0),
        #000,
        hsla(0, 0%, 100%, 0)
      );
    }
  }

  &.horizontal {
    background-color: transparent;
  }
}
