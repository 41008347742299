.form {
  &__label {
    font-size: 1.2rem;
    font-weight: 700;
    margin-left: 2rem;
    margin-top: 0.2rem;
    margin-bottom: 0.8rem;
    display: block;
    transition: all 0.3s;
  }

  &__input {
    font-size: 1.3rem;
    font-family: inherit;
    color: inherit;
    padding: 1rem 2rem;
    border-radius: 2px;
    border: none;
    border-bottom: 3px solid transparent;
    width: 100%;
    display: block;
    transition: all 0.3s;

    &.error {
      border: 1px solid var(--color-alert);
      background-color: #ffe8e9f7;

      & ~ div.form__error {
        display: block;
      }
    }

    &:focus {
      outline: none;
      box-shadow: var(--shadow-light);
      border-bottom: 3px solid var(--color-secondary);
    }

    &:focus:invalid {
      border-bottom: 3px solid var(--color-alert);
    }

    &::-webkit-input-placeholder {
      color: var(--color-grey-dark-1);
    }
  }

  &__input:placeholder-shown + &__label {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-4rem);
  }

  &__error {
    color: var(--color-alert);
    position: absolute;
    top: -20px;
    left: 1px;
    font-size: 12px;
    display: none;
  }

  &__noresize {
    resize: none;
  }

  &__group {
    z-index: 1;
    position: relative;
    margin-bottom: 0.8rem;
  }

  &__group.message {
    grid-column: 1/ -1;
  }
  &__group.center {
    grid-column: 1 / -1;
    margin: 0 auto;
  }

  &__groupelement {
    width: fit-content;
    display: inline-block;

    &-input {
      display: none;
    }

    &-label {
      font-size: $default-font-size;
      cursor: pointer;
      position: relative;
      padding-left: 0.5rem;
    }

    &-button {
      display: inline-block;
      position: absolute;
      left: 0;

      &::after {
        content: "";
        display: block;
        position: absolute;
        background-color: $color-primary;
        opacity: 0.1;
        transition: opacity 0.2s;
      }

      &.circular {
        height: 3rem;
        width: 3rem;
        border: 4px solid $color-primary;
        border-radius: 50%;
        top: -0.4rem;

        &::after {
          height: 70%;
          width: 70%;
          border-radius: 50%;

          transform: translate(23%, 20%);
        }
      }

      &.rectangular {
        height: fit-content;
        width: max-content;
        border: 4px solid $color-primary;
        font-size: 1.2rem;
        text-align: center;
        color: var(--color-grey-dark-3);
        padding: 0.1rem 0.5rem;

        &::after {
          height: 82%;
          width: 95%;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: -1;
        }
      }
    }

    &-buttonc {
      height: fit-content;
      width: max-content;
      min-width: 8rem;
      border: 3px solid var(--color-primary-light);
      display: inline-block;
      font-size: 1.4rem;
      text-align: center;
      border-radius: 20px;
      color: var(--color-secondary-dark);
      padding: 0rem 1.2rem;
      position: relative;
      z-index: 2;

      &::after {
        content: "";
        display: block;
        height: 88%;
        width: 96%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: var(--color-secondary-dark);
        opacity: 0.1;
        transition: opacity 0.2s;
        z-index: -1;
        border-radius: 20px;
      }
    }

    &.radio {
      margin-right: 1.2rem;
    }

    &.checkbox {
      z-index: 1;
      margin-bottom: 0.8rem;
    }
  }

  &__groupelement-input:checked ~ &__groupelement-label &__groupelement-button {
    color: var(--color-grey-dark-2);

    &::after {
      opacity: 1;
    }
  }

  &__groupelement-input:checked
    ~ &__groupelement-label
    &__groupelement-buttonc {
    color: var(--color-white);
    border-color: var(--color-secondary-dark);

    &:after {
      opacity: 1;
    }
  }

  &__form {
    padding: 4rem 2rem;

    @include respond(s-es) {
      padding: 4rem 5rem;
    }

    &-start {
      margin-bottom: 2rem;
      background-color: var(--color-grey-light-3);
      box-shadow: var(--shadow-dark);
    }
  }
}
