*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  box-sizing: border-box;
  background-color: var(--color-body);
  overflow-x: hidden;
}

::selection {
  background-color: var(--color-primary);
  color: var(--color-white);
}

#page-root {
  display: grid;
  height: 100vh;
  transition: all 0.2s ease-in-out;

  &.expanded {
    grid-template-columns: minmax(22.5rem, max-content) 1fr;
  }

  &.collapsed {
    grid-template-columns: minmax(9.5rem, max-content) 1fr;
  }
}

.bg-gradient-primary {
  background-image: linear-gradient(
    195deg,
    var(--color-secondary-light),
    var(--color-secondary-dark)
  );
}

.pointer {
  cursor: pointer;
}

.w-100 {
  width: 100%;
}
