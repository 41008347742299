.screen-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 50%);
}

.d-none {
  display: none !important;
}

.u-mt {
  &-1 {
    margin-top: 0.5rem;
  }
  &-2 {
    margin-top: 1rem;
  }
  &-3 {
    margin-top: 1.5rem;
  }
  &-4 {
    margin-top: 2rem;
  }
  &-5 {
    margin-top: 2.5rem;
  }
}

.u-pt {
  &-s-1 {
    padding-top: 2px;
  }

  &-1 {
    padding-top: 0.5rem;
  }
  &-2 {
    padding-top: 1rem;
  }
  &-3 {
    padding-top: 1.5rem;
  }
  &-4 {
    padding-top: 2rem;
  }
  &-5 {
    padding-top: 2.5rem;
  }
}

.u-pb {
  &-s-1 {
    padding-bottom: 2px;
  }

  &-1 {
    padding-bottom: 0.5rem;
  }
  &-2 {
    padding-bottom: 1rem;
  }
  &-3 {
    padding-bottom: 1.5rem;
  }
  &-4 {
    padding-bottom: 2rem;
  }
  &-5 {
    padding-bottom: 2.5rem;
  }
}

.u-pl {
  &-1 {
    padding-left: 0.5rem;
  }
  &-2 {
    padding-left: 1rem;
  }
  &-3 {
    padding-left: 1.5rem;
  }
  &-4 {
    padding-left: 2rem;
  }
  &-5 {
    padding-left: 2.5rem;
  }
}
