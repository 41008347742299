.headerContainer {
  padding: 0 2rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-items: center;
  color: var(--color-grey-dark-3);

  .userContainer {
    display: flex;
    gap: 1rem;
    align-items: baseline;
    font-weight: 600;

    .userName {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    .notifications {
      position: relative;

      &__number {
        position: absolute;
        background-color: var(--color-alert);
        color: var(--color-white);
        top: -0.8rem;
        right: -0.7rem;
        width: 1.3rem;
        height: 1.3rem;
        font-size: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2rem;
      }
    }
  }
}
