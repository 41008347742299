//COLORS

:root {
  --color-primary: #feca3e;
  --color-primary-light: #ffe8ab;
  --color-primary-dark: #e6a700;

  --color-secondary: #ce3517;
  --color-secondary-light: #e84e31;
  --color-secondary-dark: #982711;

  --color-white: #ffffff;
  --color-black: #000000;

  --color-succuss: #22bb33;
  --color-danger: #bb2124;
  --color-warning: #f0ad4e;
  --color-info: #5bc0de;

  --color-grey-light-1: #faf9f9;
  --color-grey-light-2: #f4f2f2;
  --color-grey-light-3: #f0eeee;
  --color-grey-light-4: #ccc;

  --color-grey-dark-1: #1a1a1a;
  --color-grey-dark-2: #3b3b3b;
  --color-grey-dark-3: #777;
  --color-grey-dark-4: #999;

  --color-alert: #f51720;
  --color-body: #e4e4e4de;
  --color-overlay: #00000021;

  --shadow-darkest: 0 4rem 7rem rgba(0, 0, 0, 0.5);
  --shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.3);
  --shadow-light: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);

  --line: 1px solid var(--color-grey-light-2);
}

$default-font-size: 1.6rem;
$color-white: #fff;
$color-primary: #feca3e;
$color-primary-light: #ffe8ab;
$color-secondary: #614a45;

//breakpoints
//minimum supported size: 18.12em (290px)
$bp-smallest: 21.87em; //350px ees
$bp-smaller: 31.25em; //500px  es
$bp-small: 37.5em; //600px     s
$bp-medium: 56.25em; //900px   m
$bp-big: 68.75em; //1100px     b
$bp-bigger: 93.75em; //1500px  eb
$bp-biggest: 112em; //1800px   eeb
$bp-large: 125em; //2000px     el
$bp-largest: 187.5em; //3000px eel
