.btn,
input[type="submit"].um-button,
.mgbutton,
.ina-modal-inside .ina-modal-actions button.ina-button {
  &,
  &:link,
  &:visited,
  &:focus {
    padding: 1.2rem 8rem;
    font-size: 1.4rem;
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
    text-decoration: none;
    border-radius: 10rem;
    box-shadow: var(--shadow-light);
    display: inline-block;
    transition: all 0.2s;
    position: relative;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    word-spacing: 4px;

    @include respond(s-m) {
      font-size: 1.8rem;
      padding: 1.5rem 3rem;
    }

    @include respond(s-es) {
      padding: 1.5rem 2rem;
      font-size: 1.7rem;
    }

    @include respond(s-ees) {
      font-size: 1.5rem;
    }
  }

  &:hover {
    transform: translateY(-0.3rem);
    box-shadow: var(--shadow-dark);
  }

  &:active {
    outline: none;
    transform: translateY(-1px);
    box-shadow: var(--shadow-light);
  }

  .loader {
    height: 2rem;
    width: 2rem;
    margin-right: 1rem;
  }
}

.btn__close {
  height: 1.8rem;
  width: 1.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-secondary);
  background-color: var(--color-white);
  border-radius: 50%;
  padding: 0.4rem;
  font-size: 1.6rem;
  font-weight: bold;
  transition: all 0.2s;
  cursor: pointer;

  @include respond(s-es) {
    font-size: 1rem;
    padding: 0.3rem;
  }

  &:hover {
    height: 1.9rem;
    width: 1.9rem;
    font-size: 1.8rem;
    background-color: var(--color-grey-light-4);
  }
}

.btn-view-more {
  &,
  &:link,
  &:visited,
  &:focus {
    padding: 0.4rem 1.5rem;
    font-size: 0.8rem;
    font-weight: 800;
    letter-spacing: 0.2rem;
    text-decoration: none;
    border-radius: 0.8rem;
    box-shadow: var(--shadow-light);
    width: fit-content;
    color: var(--color-primary);
    border: 1px solid currentColor;
    display: inline-block;
    transition: all 0.2s;
    position: relative;

    @include respond(s-es) {
      font-size: 1rem;
    }
  }

  &:hover {
    color: var(--color-primary-dark);
    transform: translateY(-0.3rem);
    box-shadow: var(--shadow-dark);
  }

  &:active {
    outline: none;
    transform: translateY(-1px);
    box-shadow: var(--shadow-light);
  }
}
