#sidebar {
  background-image: linear-gradient(
    195deg,
    var(--color-grey-dark-2),
    var(--color-grey-dark-1)
  );
  color: var(--color-white);
  margin: 1.2rem;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 1.2rem;
  position: fixed;
  height: 95vh;

  .sidebar {
    &__container {
      padding: 1rem;
      transition: width 0.2s ease-in-out;

      &.expanded {
        width: 20rem;
      }

      &.collapsed {
        width: 6rem;
      }
    }

    &__collapsearrow {
      position: absolute;
      top: 0.5rem;
      right: -1.6rem;
      background-color: black;
      border-radius: 1rem;
      padding: 2px;
      cursor: pointer;
    }
  }

  .logo {
    width: 3.2rem;
    height: 3.2rem;
    background: var(--color-white);
    border-radius: 2rem;

    &__container {
      display: flex;
      align-items: center;

      gap: 1rem;

      &.expanded {
        justify-content: flex-start;
      }

      &.collapsed {
        justify-content: center;
      }
    }
  }

  .logout {
    width: 100%;
    padding: 1rem;
    text-align: center;
    color: var(--color-white);
    text-decoration: none;
    border-radius: 0;
    font-size: 1.2rem;
    border-radius: 0 0 20px 20px;

    &__icon {
      &.expanded {
        margin-right: 0.5rem;
      }
    }
  }
}

.pageContent {
  margin: 1.5rem 1.2rem 1.5rem 0;
  font-size: 1.2rem;
  grid-column: 2/-1;
}
