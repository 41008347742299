.loginPage {
  background-image: url("/images/login-bg.jpg");
  background-size: cover;
  background-position: center;
  height: 100vh;
  position: relative;

  &__logo {
    width: 12rem;
    height: auto;
    padding-bottom: 1rem;
  }

  &__formContainer {
    width: 50rem;
    text-align: center;
    margin: 0 auto;
    height: 100vh;
    position: relative;
    padding: 2rem;
    padding-top: 5rem;
  }

  form {
    padding: 2rem 8rem 6rem 8rem;
    background-color: var(--color-overlay);
    position: relative;

    input {
      border-radius: 5rem;
    }

    button {
      width: 100%;
    }
  }
}
