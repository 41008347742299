.toast {
  &__container {
    position: absolute;
    z-index: 9999;

    &.top-center {
      top: 1rem;
      left: 50%;
      transform: translateX(-50%);
    }
    &.bottom-center {
      bottom: 1rem;
      left: 50%;
      transform: translateX(-50%);
    }
    &.top-left {
      top: 1rem;
      left: 1rem;
    }
    &.top-right {
      top: 1rem;
      right: 1rem;
    }
    &.bottom-left {
      bottom: 1rem;
      left: 1rem;
    }
    &.bottom-right {
      bottom: 1rem;
      right: 1rem;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &.top-center,
    &.bottom-center {
      align-items: center;
    }

    &.top-left,
    &.bottom-left {
      align-items: flex-start;
    }
  }

  &__item {
    width: fit-content;
    padding: 0.5rem;
    position: relative;
    display: flex;
    justify-content: space-between;
    border-radius: 0 6px 6px 0;
    background-color: var(--color-gray-1);
    box-shadow: 0 0 12px -5px currentColor;

    &::before {
      content: "";
      width: 0.5rem;
      height: 100%;
      border-radius: 6px 0 0 6px;
      background-color: currentColor;
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(-90%);
    }

    &-progress {
      height: 3px;
      position: absolute;
      bottom: 0;
      left: 0;
      border-radius: 0 0 6px 0;
      background-color: currentColor;
    }

    &.success {
      color: var(--color-succuss);
    }

    &.danger {
      color: var(--color-danger);
    }

    &.warning {
      color: var(--color-warning);
    }

    &.info {
      color: var(--color-info);
    }

    &.top-right,
    &.bottom-right {
      animation: slide_in_from_right 0.5s ease-in;
    }

    &.top-left,
    &.bottom-left {
      animation: slide_in_from_left 0.5s ease-in;
    }

    &.top-center {
      animation: slide_in_from_top 0.5s ease-in;
    }

    &.bottom-center {
      animation: slide_in_from_bottom 0.5s ease-in;
    }

    &-text {
      text-shadow: 0.5px 0.5px 10px #fff;
      font-size: 1.1rem;
    }

    &-close {
      position: relative;
      cursor: pointer;
      font-size: 1.3rem;
    }
  }
}

@keyframes slide_in_from_right {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}

@keyframes slide_in_from_left {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}

@keyframes slide_in_from_top {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0%);
  }
}

@keyframes slide_in_from_bottom {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}
