.card {
  width: 95%;
  height: 95%;
  padding: 1rem;
  background-color: var(--color-white);
  border-radius: 2rem;
  box-shadow: var(--shadow-light);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &__icon {
    height: 4rem;
    width: 4rem;
    background-color: var(--color-primary);
    position: absolute;
    top: -1rem;
    left: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    box-shadow: var(--shadow-light);

    &.black {
      background-color: var(--color-grey-dark-1);
    }

    &.primary {
      background-color: var(--color-primary);
    }

    &.secondary {
      background-color: var(--color-secondary);
    }

    &.info {
      background-color: var(--color-info);
    }
  }

  &__count {
    font-size: 2rem;
    padding-right: 1rem;
    font-weight: 700;
    color: currentColor;
  }

  &__title {
    color: var(--color-grey-dark-4);
    font-size: 1.2rem;
  }

  &__btnContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
}
