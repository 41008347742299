.nav {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  padding: 1rem 0;

  &__link {
    color: var(--color-white);
  }

  li {
    border-radius: 0.5rem;
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 1rem;

    a {
      color: var(--color-white);
      text-decoration: none;
    }

    &:hover {
      background-image: linear-gradient(
        195deg,
        var(--color-grey-dark-3),
        var(--color-grey-dark-2)
      );
    }

    &.active {
      background-image: linear-gradient(
        195deg,
        var(--color-secondary-light),
        var(--color-secondary-dark)
      );
    }
  }

  .link {
    &__item {
      height: 100%;
      width: 100%;
      display: block;
      padding: 1rem 0.5rem;
      display: flex;
      align-items: center;
      gap: 1rem;

      &.collapsed {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &__icon {
      &.expanded {
        margin-left: 1rem;
      }
    }
  }
}
