.container {
  display: grid;
  grid-template-rows: minmax(min-content, 12rem) minmax(25vh, 1fr);

  .cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    align-items: center;
    justify-items: center;
    column-gap: 0.5rem;
    row-gap: 1.8rem;
  }

  .details {
    display: grid;
    grid-template-columns: 1fr 25%;
    margin-top: 3rem;

    .container {
      padding: 1rem;
      border-radius: 2rem;
      background-color: var(--color-white);
      margin: 0.5rem;
      box-shadow: var(--shadow-light);
    }
  }
}
